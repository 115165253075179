const cioHomePageData = {
  brandImageGrids: {
    big30Years: {
      fullPortrait: {
        altText: 'Annie Petito making Roast Chicken and Bread Salad',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/30 Years/Desktop/Desktop-1.jpg',
      },
      fullLandscape: {
        altText: 'Aged Beef',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/30 Years/Desktop/Desktop-2.jpg',
      },
      square1: {
        altText: 'French Strawberry Cake',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/30 Years/Desktop/Desktop-3.jpg',
      },
      square2: {
        altText: 'Iced Tea',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/30 Years/Desktop/Desktop-4.jpg',
      },
    },
    bigBakedInTheOven: {
      fullPortrait: {
        altText: 'Dan and Lan with Black Hole Cake',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Baked in the Oven of ATK/Desktop/Baked-1.jpg',
      },
      fullLandscape: {
        altText: 'How to cook turkey for a crowd',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Baked in the Oven of ATK/Desktop/Baked-2.jpg',
      },
      square1: {
        altText: 'Chili Spiced Chicken Thighs and Potatoes',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Baked in the Oven of ATK/Desktop/Baked-3.jpg',
      },
      square2: {
        altText: 'Chocolate Olive Oil Cake',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Baked in the Oven of ATK/Desktop/Baked-4.jpg',
      },
    },
  },
  brandHeroes: {
    bhCulinaryProwess: {
      altText: 'Sharpen your culinary prowess through science',
      cloudinaryIds: {
        desktop:
          '/2023 Brand Pages/Cook%27s Illustrated/Heroes/DesktopCI-Hero.jpg',
        tablet:
          '/2023 Brand Pages/Cook%27s Illustrated/Heroes/TabletCI-Hero.jpg',
        mobile:
          '/2023 Brand Pages/Cook%27s Illustrated/Heroes/MobileCI-Hero.jpg',
      },
    },
    bhTeach: {
      altText: 'Our favorite way to teach is showing',
      cloudinaryIds: {
        desktop:
          '/2023 Brand Pages/Cook%27s Illustrated/Our favorite way Image Break 1/desktop-ourfavorite.jpg',
        tablet:
          '/2023 Brand Pages/Cook%27s Illustrated/Our favorite way Image Break 1/tablet-ourfavorite.jpg',
        mobile:
          '/2023 Brand Pages/Cook%27s Illustrated/Our favorite way Image Break 1/mobile-ourfavorite.jpg',
      },
    },
    bhJoinAndLearn: {
      altText: 'Come join us and learn how to cook your best',
      cloudinaryIds: {
        desktop:
          '/2023 Brand Pages/Cook%27s Illustrated/Come Join Us and Learn/desktop-comejoinus.jpg',
        tablet:
          '/2023 Brand Pages/Cook%27s Illustrated/Come Join Us and Learn/tablet-comejoinus.jpg',
        mobile:
          '/2023 Brand Pages/Cook%27s Illustrated/Come Join Us and Learn/mobile-comejoinus.jpg',
      },
    },
  },
  fullWidthContentBlocks: {
    fwb30Years: {
      header:
        "<span class='has-highlight'>30 Years</span> of Revolutionizing Home Cooking",
      content:
        "We explore the science behind what makes a recipe tick. We ask questions, conduct experiments, and get answers—delicious answers that you won't find anywhere else.",
    },
    fwcbBakedInTheOven: {
      header: 'Why You Should Trust Our Recipes',
      content:
        "We’re the flagship brand behind America’s Test Kitchen's rigorous and scientific recipe development process. We make recipes that work—no hit or miss, no drama. Our recipes have been prodded, tweaked, and perfected until they're failproof in any kitchen.",
    },
  },
  fullWidthHeadlines: {
    fwhJoinUs: {
      header: 'Join Us in the Lab',
    },
    fwhWInnovativeContent: {
      header: 'Our Innovative Content Is Everywhere You Are',
    },
  },
  fullWidthCarousel: {
    fwcImages: [
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Our Innovative Content/Desktop/CI-Mag-1.jpg',
        altText: "Cook's Illustrated Magazine",
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Our Innovative Content/Desktop/CI-Mag-2.jpg',
        altText: "Cook's Illustrated Magazine",
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Our Innovative Content/Desktop/CI-Mag-3.jpg',
        altText: "Cook's Illustrated Magazine",
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Our Innovative Content/Desktop/CI-Mag-4.jpg',
        altText: "Cook's Illustrated Magazine",
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Illustrated/Our Innovative Content/Desktop/CI-Mag-5.jpg',
        altText: "Cook's Illustrated Magazine",
      },
    ],
  },
  fullWidthVideo: {
    fwvWhatsEattingDan: {
      zypeIdSource: '6501faab4431d70001c272c5',
    },
    fwvTechniquely: {
      zypeIdSource: '632dd9b84df85b000144d028',
    },
  },
  gameChangersCarousel: [
    {
      cloudinaryId: '/SFS_Weeknight_Roast_Chicken_317_baaoe0',
      headline: 'Weeknight Roast Chicken',
      link: '/recipes/6682-weeknight-roast-chicken',
    },
    {
      cloudinaryId: '28299_sfs-chocolate-chip-cookie-75',
      headline: 'Perfect Chocolate Chip Cookies',
      link: '/recipes/4737-perfect-chocolate-chip-cookies',
    },
    {
      cloudinaryId: 'SFS_GrilledBonelessBeefShortRibs-135_we7azc',
      headline: 'Grilled Boneless Beef Short Ribs',
      link: '/recipes/13145-grilled-boneless-beef-short-ribs',
    },
    {
      cloudinaryId:
        '/37840_sfs-skillet-roasted-brussels-sprouts-chile-peanuts-mint-11',
      headline:
        'Skillet-Roasted Brussels Sprouts with Chile, Peanuts, and Mint',
      link: '/recipes/10261-skillet-roasted-brussels-sprouts-with-chile-peanuts-and-mint',
    },
    {
      cloudinaryId: '/30033_sfs-foolproofthincrustpizza-022',
      headline: 'New York–Style Thin-Crust Pizza',
      link: '/recipes/6316-thin-crust-pizza',
    },
    {
      cloudinaryId: '/30301_sfs-korean-fried-chicken-wings-48',
      headline: 'Dakganjeong (Korean Fried Chicken Wings)',
      link: '/recipes/8885-korean-fried-chicken-wings',
    },
    {
      cloudinaryId: '/SFS_Braised_Asparagus_with_Lemon_and_Chives_021_duq5x4',
      headline: 'Braised Asparagus with Lemon and Chives',
      link: '/recipes/14703-braised-asparagus-with-lemon-and-chives',
    },
    {
      cloudinaryId: '33684_sfs-easy-to-peel-hard-cooked-eggs-24',
      headline: 'Easy-Peel Hard-Boiled Eggs',
      link: '/recipes/8712-easy-peel-hard-cooked-eggs',
    },
    {
      cloudinaryId: '/Assassina-4_qgychp',
      headline: "Spaghetti All'Assassina",
      link: '/recipes/15027-spaghetti-all-assassina',
    },
    {
      cloudinaryId: '/43960-sfs-indian-butter-chicken-for-two-35-1',
      headline: 'Murgh Makhani (Indian Butter Chicken)',
      link: '/recipes/11519-indian-butter-chicken-murgh-makhani',
    },
    {
      cloudinaryId: '/34431_sfs-saltcuredeggyolks-6',
      headline: 'Salt-Cured Egg Yolks',
      link: '/recipes/9589-salt-cured-egg-yolks',
    },
    {
      cloudinaryId: '/43110-sfs-biscuits-8052',
      headline: 'Easiest-Ever Biscuits',
      link: '/recipes/11334-easiest-ever-biscuits',
    },
  ],
  allTimeFavoritesCarousel: [
    {
      cloudinaryId: '/SFS_cast_iron_pan_pizza_056_1_twzoux',
      headline: 'Cast Iron Pan Pizza',
      link: '/recipes/12219-cast-iron-pan-pizza',
    },
    {
      cloudinaryId: '/SFS_Millionaire_Shortbread_418_1_fi4esc',
      headline: "Millionaire's Shortbread",
      link: '/recipes/9253-millionaires-shortbread',
    },
    {
      cloudinaryId: '/SFS_ultra_creamy_hummus_060_1_er9ulh',
      headline: 'Ultracreamy Hummus',
      link: '/recipes/12228-ultracreamy-hummus',
    },
    {
      cloudinaryId: '/25542_sfs-miso-glazed-salmon-22',
      headline: 'Miso-Marinated Salmon',
      link: '/recipes/8572-miso-marinated-salmon',
    },
    {
      cloudinaryId: '/SFS_smashed_burgers_reshoot_130_bblunw',
      headline: 'Smashed Burgers',
      link: '/recipes/12825-smashed-burgers',
    },
    {
      cloudinaryId: '/39885_sfs-brazilianfishstew-63-1',
      headline: 'Moqueca (Brazilian Fish and Shrimp Stew)',
      link: '/recipes/10603-brazilian-shrimp-and-fish-stew-moqueca',
    },
    {
      cloudinaryId: '/34928_sfs-olive-oil-cake-91',
      headline: 'Olive Oil Cake',
      link: '/recipes/9648-olive-oil-cake',
    },
    {
      cloudinaryId: '/34219_sfs-really-good-garlic-bread-28',
      headline: 'Really Good Garlic Bread',
      link: '/recipes/9530-really-good-garlic-bread',
    },
    {
      cloudinaryId: '/30288_sfs-shredded-chicken-tacos-16',
      headline: 'Tinga de Pollo (Shredded Chicken Tacos)',
      link: '/recipes/8891-shredded-chicken-tacos-tinga-de-pollo',
    },
    {
      cloudinaryId: '/28474_sfs-banana-bread-49',
      headline: 'Ultimate Banana Bread',
      link: '/recipes/6067-ultimate-banana-bread',
    },
    {
      cloudinaryId:
        '/41523-sps-vietnamese-grilled-pork-patties-rice-noodles-salad-143',
      headline: 'Bún Chả',
      link: '/recipes/11061-vietnamese-grilled-pork-patties-with-rice-noodles-and-salad-bun-cha',
    },
    {
      cloudinaryId: '/39967_sfs-classic-lemon-bar-86-1',
      headline: 'Best Lemon Bars',
      link: '/recipes/10616-best-lemon-bars',
    },
  ],
  teamMemberCarouselItems: {
    title: 'Meet the Team',
    authorCards: [
      {
        cloudinaryId: '/Play Cast Headshots/staff_dan_souza',
        description:
          "Dan is the editor in chief of <i>Cook's Illustrated</i>, a cast member of <i>America's Test Kitchen</i>, and host of the James Beard Award-nominated YouTube series <i>What's Eating Dan?</i>",
        name: 'Dan Souza',
        path: 'authors/43',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_Amanda_Agee_23',
        description:
          'Amanda is editorial director of <i>Cook’s Illustrated</i>. She started her career in food journalism at <i>Gourmet Magazine</i>. She loves dinner for breakfast and dirty martinis.',
        name: 'Amanda Agee',
        path: 'authors/108',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_becky_hays',
        description:
          "Becky is deputy editor of <i>Cook's Illustrated</i> and a cast member of <i>America's Test Kitchen</i>. She's a Natural Gourmet Institute grad who takes a mostly vegetarian approach to food.",
        name: 'Rebecca Hays',
        path: 'authors/45',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_keith_dresser',
        description:
          "Keith is the executive food editor of <i>Cook's Illustrated</i> and a cast member of <i>America's Test Kitchen</i>. He cooked professionally in New England before joining ATK in 2002.",
        name: 'Keith Dresser',
        path: 'authors/44',
      },
      {
        cloudinaryId: 'AKO%20Articles/Author_Headshots/staff_liz_bomze.jpg',
        description:
          "Liz is managing editor of <i>Cook's Illustrated</i>. She's picky about lemons and syntax and consumes chewy noodles with abandon.",
        name: 'Elizabeth Bomze',
        path: 'authors/120',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_andrea_geary',
        description:
          "Andrea is deputy food editor of <i>Cook's Illustrated</i>. She's a breakfast enthusiast and kitchen scale evangelist.",
        name: 'Andrea Geary',
        path: 'authors/50',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_lam_lam',
        description:
          "Lan is a senior editor of <i>Cook's Illustrated</i>, a cast member of <i>America's Test Kitchen</i>, and host of the James Beard Award-nominated YouTube series <i>Techniquely with Lan Lam</i>.",
        name: 'Lan Lam',
        path: 'authors/41',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_annie_petito',
        description:
          "Annie is a senior editor of <i>Cook's Illustrated</i>. She's a culinary school grad and a lover of all things snack related.",
        name: 'Annie Petito',
        path: 'authors/51',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_steve_dunn',
        description:
          "Steve is a senior editor of <i>Cook's Illustrated</i>. He's a graduate of Le Cordon Bleu in Paris where he lived for two years. ",
        name: 'Steve Dunn',
        path: 'authors/52',
      },
      {
        cloudinaryId: 'AKO Articles/Author_Headshots/staff_Alyssa_Vaughn_23',
        description:
          "Alyssa is senior content editor of <i>Cook's Illustrated</i>. She's a journalist with a penchant for semicolons and cookie dough.",
        name: 'Alyssa Vaughn',
        path: 'authors/191',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_Erica_Turner_23',
        description:
          "Erica is an associate editor of <i>Cook's Illustrated</i>. She attended culinary school in Gaziantep, Turkey.",
        name: 'Erica Turner',
        path: 'authors/190',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_eric_haessler',
        description:
          "Eric is the lead photo test cook for <i>Cook's Illustrated</i> and an instructor for ATK's Online Cooking School. ",
        name: 'Eric Haessler',
        path: 'authors/153',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_paul_adams',
        description:
          "Paul was a cook, science reporter, bartender, and bike courier before he combined it all as ATK's senior science research editor.",
        name: 'Paul Adams',
        path: 'authors/78',
      },
    ],
  },
  twoUpCarousels: {
    tucTransformative: [
      {
        href: '/cooksillustrated/articles/2075-can-baking-soda-remove-pesticides-from-produce',
        images: {
          cloudinaryId:
            '/CIO Web Articles/CI JF 20/Non-Recipe Articles/CAN_RemovingPesticides_5589',
        },
        headline: 'Can Baking Soda Remove Pesticides from Produce?',
        description:
          'A study found that soaking produce in baking soda solution can remove common pesticides. We conducted our own experiment to see how it compares to other common methods.',
        authors: [
          {
            id: 1,
            firstName: 'Lan',
            lastName: 'Lam',
            image: {
              altText: 'Lan Lam Headshot',
              url: '/Play Cast Headshots/staff_lam_lam',
            },
          },
        ],
      },
      {
        href: '/cooksillustrated/how_tos/8741-the-science-of-cooking-frozen-steaks',
        images: {
          cloudinaryId:
            '50l8vbu0tu55-SFS_Thick-Cut-Steaks-with-Herb-Butter_034',
        },
        headline: 'Can You Cook a Frozen Steak?',
        description:
          'Conventional wisdom holds that frozen steaks should be thawed before cooking. Our testing proved otherwise. Learn why forgetting to defrost steak ahead of time isn’t necessarily a bad thing.',
        authors: [
          {
            id: 1,
            firstName: 'Dan',
            lastName: 'Souza',
            image: {
              altText: 'Dan Souza Headshot',
              url: '/Play Cast Headshots/staff_dan_souza',
            },
          },
        ],
      },
      {
        href: '/cooksillustrated/articles/5709-why-very-hot-and-very-cold-foods-need-more-salt',
        images: { cloudinaryId: '/STP_SauteedTilapia_Salt_21_ndr7vc.jpg' },
        headline: 'Why Very Hot and Very Cold Foods Need More Salt',
        description:
          'Serving temperature of food dramatically affects how seasoned it tastes. The more extreme the temperature—whether piping hot or very chilled—the more salt you need. We ran an experiment to prove it.',
        authors: [
          {
            id: 1,
            firstName: 'Liz',
            lastName: 'Bomze',
            image: {
              altText: 'Liz Bomze Headshot',
              url: 'AKO%20Articles/Author_Headshots/staff_liz_bomze.jpg',
            },
          },
        ],
      },
      {
        href: '/cooksillustrated/articles/4186-temper-chocolate-with-a-microwave-and-box-grater',
        images: {
          cloudinaryId:
            '/CIO Web Articles/CIO Articles 2022/January Articles/Chocolate',
        },
        headline: 'Temper Chocolate with a Microwave and a Box Grater',
        description:
          'Forget the fussy heat-cool-reheat method. With just a microwave and a box grater you can create shiny, snappy chocolate for drizzling, dipping, or coating in no time.',
        authors: [
          {
            id: 1,
            firstName: 'Liz',
            lastName: 'Bomze',
            image: {
              altText: 'Liz Bomze Headshot',
              url: 'AKO%20Articles/Author_Headshots/staff_liz_bomze.jpg',
            },
          },
        ],
      },
    ],
    tucInnovative: [
      {
        href: '/recipes/8418-ultimate-charcoal-grilled-steaks',
        images: {
          cloudinaryId: '24440_sfs-ultimate-grilled-thick-cut-steak-120',
        },
        headline: 'Ultimate Charcoal-Grilled Steaks',
        description:
          'The secret to delivering a charcoal-grilled steak with a killer crust and perfectly cooked meat from edge to edge? Keep the coals in the chimney and think outside the grill.',
        authors: [
          {
            id: 1,
            firstName: 'Dan',
            lastName: 'Souza',
            image: {
              altText: 'Dan Souza Headshot',
              url: 'Play Cast Headshots/staff_dan_souza',
            },
          },
        ],
      },
      {
        href: '/recipes/10011-caramelized-onions',
        images: {
          cloudinaryId: '/37106_sfs-caramelized-onions-123',
        },
        headline: 'Caramelized Onions',
        description:
          'The sweetest, most complex caramelized onions usually take an hour of careful cooking over a low flame. We cut that time in half with an ingredient we least expected: water.',
        authors: [
          {
            id: 1,
            firstName: 'Lan',
            lastName: 'Lam',
            image: {
              altText: 'Lan Lam Headshot',
              url: '/Play Cast Headshots/staff_lam_lam',
            },
          },
        ],
      },
      {
        href: '/recipes/6604-perfect-scrambled-eggs',
        images: {
          cloudinaryId: '29888_sfs-foolproof-scrambled-eggs-023',
        },
        headline: 'Perfect Scrambled Eggs',
        description:
          'The classic low-heat approach will never give you tender scrambled eggs with big, pillowy curds. But achieving scrambled egg perfection takes more than just turning up the heat.',
        authors: [
          {
            id: 1,
            firstName: 'Dan',
            lastName: 'Souza',
            image: {
              altText: 'Dan Souza Headshot',
              url: 'Play Cast Headshots/staff_dan_souza',
            },
          },
        ],
      },
      {
        href: '/recipes/8654-best-baked-potatoes',
        images: {
          cloudinaryId: 'SFS_Best_Baked_Potatoes_051_2_g4qjl7',
        },
        headline: 'The Best Baked Potato',
        description:
          'We wanted a uniformly fluffy interior encased in thin, crisp skin—and we baked nearly 200 pounds of potatoes to get it. The key to our success? An instant-read thermometer.',
        authors: [
          {
            id: 1,
            firstName: 'Lan',
            lastName: 'Lam',
            image: {
              altText: 'Lan Lam Headshot',
              url: '/Play Cast Headshots/staff_lam_lam',
            },
          },
        ],
      },
    ],
  },
  twoColumnTexts: {
    tctGoodScience: {
      description:
        "We believe that a little science goes a long way to helping you understand what's going on in your food and in your kitchen. You'll learn why a technique works or how an ingredient creates an important effect.",
      headline: '<em>Good science</em> makes  great food',
    },
    tctOurSignature: {
      description:
        "We put every recipe through the same rigorous process, testing and retesting every variable and then taking what we've learned to create a dish that's the very best in its class.",
      headline: 'Our Signature Process',
    },
    tctMagazineCarousel: {
      description:
        'Our foolproof recipes, explorations into food science, and thoroughly researched stories set new standards for what a cooking magazine can be.',
      headline: '<em>Access 30 Years</em> of Our Award-Winning Magazine',
      ctaLinks: [
        {
          className: 'atk-button-styles',
          linkText: 'Subscribe & Save 53%',
          linkHref:
            'https://cooksillustrated.americastestkitchen.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=IWC23JHR1',
          target: '_blank',
        },
        {
          linkHref: '/cooksillustrated/magazines',
          linkText: 'Browse Issues',
        },
      ],
    },
    tctWhatsEatingDan: {
      description:
        '<i>Cook’s Illustrated</i> Editor in Chief Dan Souza goes deep on his favorite ingredients and dishes in this smart, fun series dedicated to understanding the science of good cooking.',
      headline: "What's Eating Dan?",
      ctaLinks: [
        {
          linkHref:
            'https://www.youtube.com/playlist?list=PLnbzopdwFrnZc-UgGYETAQair7VzS7_Z8',
          linkText: 'Watch All 6 Seasons',
          target: '_blank',
        },
      ],
    },
    tctTechniquely: {
      description:
        'Veteran restaurant cook and chemistry major, <i>Cook’s Illustrated</i> Senior Editor Lan Lam delves into some of our most surprising—and even counterintuitive—techniques, including cold-searing steak and overcooking vegetables.',
      headline: 'Techniquely with Lan Lam',
      ctaLinks: [
        {
          linkHref:
            'https://www.youtube.com/playlist?list=PLnbzopdwFrnYyvwrqTB_5AhufdOMisGnF',
          linkText: 'Watch Season 1',
          target: '_blank',
        },
      ],
    },
  },
  ProcessGrid: [
    {
      title: 'Our Signature Process',
      cardDeck: [
        {
          header: '1. The Five-Recipe Test',
          content:
            'First we seek out five existing recipes. We taste and assess them, noting qualities we like and any we don’t.',
          cloudinaryId:
            '/2023 Brand Pages/Cook%27s Illustrated/Signature Process/fiverecipe.png',
        },
        {
          header: '2. Our First Draft',
          content:
            'Next, we define goals for our version, and sketch out the most promising way to reach them.',
          cloudinaryId:
            '/2023 Brand Pages/Cook%27s Illustrated/Signature Process/firstdraft.png',
        },
        {
          header: '3. Test, Taste, Repeat',
          content:
            'Over at least six weeks, we test and retest every recipe variable, learning from our mistakes so we can pass on that information to you.',
          cloudinaryId:
            '/2023 Brand Pages/Cook%27s Illustrated/Signature Process/testtasterepeat.png',
        },
        {
          header: '4. Final, Failproof Results',
          content:
            'At last—a recipe that works perfectly in our kitchen and has been tested and approved by thousands of home testers.',
          cloudinaryId:
            '/2023 Brand Pages/Cook%27s Illustrated/Signature Process/finalresults.png',
        },
      ],
    },
  ],
  testimonials: [
    {
      id: 0,
      quote: 'Recipes that always, always work',
      citation: '- The New York Times',
    },
    {
      id: 1,
      quote:
        'Trust is the reason why America’s Test Kitchen’s cooking shows reach more than four million viewers.',
      citation: '- CBS News Sunday Morning',
    },
    {
      id: 2,
      quote: 'Far and away the best cooking program on television today.',
      citation: '- <span>Slate.com</span>',
    },
    {
      id: 3,
      quote: 'The two best magazines dealing with home cooking.',
      citation: '- The Wall Street Journal',
    },
  ],
};

export default cioHomePageData;
